<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('task.default[0]')"
    />
    <div class="ScrollBox">
      <van-tabs  title-active-color="var(--tabs-title-color)" color="var(--tab-line-color)"
                 title-inactive-color="var(--tabs-title-inactive-color)"
              :border="false"
              z-index="99"
              :fixed="false"
              icon-size="60"
              v-model="tabsIndex"
              @change="changeTabs"
      >
        <van-tab v-for="(item, index) in taskTabs":key="item.state">
          <template #title>
            <div>
              <p style="font-size: 16px">{{item.text}}</p>
            </div>
          </template>
        </van-tab>

      </van-tabs>
      <div class="Panel">
      <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
        <van-list
                v-model="isLoad"
                :finished="isFinished"
                @load="onLoad"
        >
          <div class="FundItem" :border="false"
                  v-for="(item, index) in listData[tabsIndex]"
                  :key="item.id" @click="gotoTask(item)">
                <div>
                  <div style="display: flex">
                    <div style="width:75%;color: var(--font_color)">
                      {{$t('hall.list[1]')}}: {{ parseFloat(item.price).toFixed(2) }} {{InitData.usdtinfo.sign}}
                    </div>
                    <div :class="item.status>2?'orderStatus2':'orderStatus'">
                      <span>{{ item.statestr}}</span>
                    </div>
                  </div>
                  <div>
                      {{$t('hall.list[0]')}}:  {{ item.amount }} USDT
                  </div>
                  <div>
                    <td colspan="2">
                      {{ item.adddate }}
                    <span style="font-size: 18px;color: var(--font_color4);padding-left: 40px">
                      {{ parseFloat(item.price*item.match_number).toFixed(2) }} {{InitData.usdtinfo.sign}}
                    </span>
                    </td>
                  </div>
                </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
      <div style="height: 80px;"/>
    </div>
    <Footer />
  </div>
</template>

<script>
  import $ from "jquery";
  export default {
    name: "TaskRecord",
    props: [],
    data() {
      return {
        listData: [],
        isLoad: false,
        isFinished: false,
        isRefresh: false,
        pageNo: 0,
        tabsState: 0,
        tabsIndex: 0,
        barActive:3,
        taskTabs2: [12,3,3,44],
        taskTabs: [
          {
            state: 0,
            text: this.$t("task3[0]"),
            img: "./static/icon/taskbar_1.png",
          },
          {
            state: 1,
            text: this.$t("task3[1]"),
            img: "./static/icon/taskbar_2.png",
          },
          {
            state: 2,
            text: this.$t("task3[2]"),
            img: "./static/icon/taskbar_3.png",
          },
          {
            state: 3,
            text: this.$t("task.tabs[3]"),
            img: "./static/icon/taskbar_4.png",
          }
        ],
        tabbar_selete: [
          "./static/icon/taskbar_01.png",
          "./static/icon/taskbar_02.png",
          "./static/icon/taskbar_03.png",
          "./static/icon/taskbar_04.png",
        ],
        fileList: [],
        beforeIndex: 0,
        taskItem: {
          id: "",
          index: "", //下标
        }, //点击保存每一项任务
        show: false,
        tc_type: "",
      };
    },
    components: {
    },
    created() {
      var ll = this.taskTabs.length;
      this.listData = [];
      for (var i = 0; i < ll; i++) {
        this.listData[i] = [];
      }
    },
    mounted() {

    },
    methods: {
      onLoad() {
        this.getListData("load");
      },
      changeTab(index){
        this.barActive = index
        this.getListData("init");
      },
      changeTabs(index) {
        this.tabsState = this.taskTabs[index].state;
        console.log(this.tabsState);
        this.getListData("init");
      },
      getListData(type) {
        this.isLoad = true;
        this.isRefresh = false;
        if (type == 'load') {
          this.pageNo += 1;
        } else {
          this.pageNo = 1;
          this.isFinished = false;
        }
        let param = {
          // status: this.barActive,
          page_no: this.pageNo,
          is_u: 2
        }
        if (this.tabsState>0){
          param['status'] = this.tabsState
        }
        var that = this;
        this.$Model.GetTaskRecord(
                param,
                data => {
                  this.isLoad = false;
                  if (data.code == 1) {
                    var items00 = data.info;
                    var items = [];
                    for (var i=0;i<items00.length;i++){
                      var item = items00[i];
                      var task_type = item['task_type']
                      var ordertype = item['ordertype']
                      var typestr = this.$t('hall4[6]')
                      if (task_type==2||ordertype==2)
                        typestr = this.$t('hall4[7]')

                      item['task_typestr'] = typestr;
                      var status = item['status']
                      var strsss = this.$t('task.tabs');
                      item['statestr'] = strsss[status];
                      items.push(item)
                    }
                    if (type == 'load') {
                      this.listData[this.tabsIndex] = this.listData[this.tabsIndex].concat(items);
                    } else {
                      this.listData[this.tabsIndex] = items;
                    }
                    if (this.pageNo == data.data_total_page) {
                      this.isFinished = true;
                    } else {
                      this.isFinished = false;
                    }
                  } else {
                    this.listData[this.tabsIndex] = '';
                    this.isFinished = true;
                  }
                }
        );
      },
      onRefresh() {
        this.getListData("init");
      },
      submitTask() {
        let { index, id } = this.taskItem;
        if (this.fileList[index]) {
          const examine_demo = this.fileList[index].map((item) => item.url);
          this.$Model.SubmitTask(
                  {
                    order_id: id,
                    examine_demo: examine_demo,
                  },
                  (data) => {
                    if (data.code == 1) {
                      this.fileList[index] = [];
                      /*  this.$Dialog.Toast(this.$t("task.msg[1]"), "center"); */
                    } else {
                      this.$Dialog.Toast(this.$t("task.msg[2]"), "center");
                    }
                  }
          );
        } else {
          this.$Dialog.Toast(this.$t("task.msg[2]"));
        }
      },
      cancelTask(id, index) {
        this.$Model.SubmitTask(
                {
                  order_id: id,
                  status: 6,
                },
                (data) => {
                  if (data.code == 1) {
                    this.fileList[index] = [];
                    this.getListData("init");
                  }
                }
        );
      },
      gotoTask(item) {
        this.$router.push("/taskDetail/"+item.id);
      },
    },
  };
</script>
<style scoped>
  .PageBox {
    overflow: auto;
    padding-top: 30px;
  }

  .van-cell__title.record > p {
    margin-bottom: 5px;
  }


  .activeTab {
    color:var(--tab-line-color);
  }

  .van-cell {
    margin-top: 5px;
  }

  .linkinfo {
    margin-left: -5px;
    text-indent: 5px;
  }

  .van-pull-refresh {
    min-height: calc(100vh - 90px);
  }

  .TaskItem {
    padding: 20px 10px;
    box-shadow: 0px 0px 3px #ccc;
  }
  .TaskItem .van-cell__title {
    line-height: 25px;
  }
  .item_title >>> h4 {
    font-size: 13px;
    font-family: emoji;
  }

  .orderStatus {
    text-align: center;
    width: 130px;
    height: 30px;
    color:var(--font_color3);
    margin-top: -5px;
    font-size: 13px;
    background-color: #2D7572;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .orderStatus2 {
    text-align: center;
    width: 130px;
    height: 30px;
    font-size: 13px;
    color: var(--font_color3);
    margin-top: -5px;
    margin-right: 0px;
    background-color: var(--button_bg3);
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .FundItem {
    width: 100%;
    background-color: var(--panel_color3);
    margin: 8px 0px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-radius: 10px;
    line-height: 30px;
    color: var(--font_subtitle);
  }
  .TaskItem .va .TaskItem .van-cell__value {
    overflow: visible;
  }

  .van-list {
    overflow: hidden;
  }

  .van-list >>> .mygrid {
    padding: 0;
  }

  .van-list >>> .icon a {
    width: 2.6rem;
    height: 2.6rem;
  }

  .TaskItem .post > p,
  .TaskItem .record > p {
    color: #444;
    font-size: 12px;
    font-family: emoji;
    margin-top: 5px;
  }

  .item_title {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
  }

  .goPage {
    margin-top: 15px !important;
  }

  .ScrollBox {
    padding-top: 10px;
  }

  .ScrollBox >>> .van-cell__value {
    margin-top: -3px;
  }

  .ScrollBox >>> .van-uploader__upload {
    background-color: #e3e6f2;
    border-radius: 4px;
  }

  .ScrollBox >>> .uploader_img .van-icon::before {
    display: none;
  }

  .orderList {
    width: 100%;
    height: auto;
    background: transparent;
    margin-bottom: 15px;
  }

</style>
