<template>
	<div class="PageBox">
		<van-nav-bar
				fixed
				:border="false"
				:title="$t('userTaskRecord[0]')"
				left-arrow
				@click-left="$router.go(-1)"
		>
		</van-nav-bar>
		<div class="bg">
			<div class="panel">
        <van-tabs :ellipsis="false" color="var(--tab-line-color)"
                  title-active-color="var(--tabs-title-color)"
                  title-inactive-color="var(--tabs-title-inactive-color)"
                  :border="false" line-width="60" v-model="active">
          <van-tab style="padding: 0 20px;">
            <template #title>
              {{ $t("userTaskRecord[1]") }}
            </template>
          </van-tab>
          <van-tab style="padding: 0 20px;">
            <template #title>
              {{ $t("userTaskRecord[2]") }}
            </template>
          </van-tab>
        </van-tabs>
				<van-list v-model="isLoad" :finished="isFinished" @load="onLoad">
					<div class="mytable" v-for="(item, index) in listData[active]" :key="item.task_id">
							<table style="font-size: 30px">
								<tr>
									<td style="color: #fff;width: 50%;font-weight: bold"> {{item.username}}</td>
									<td style="text-align: right">
										<van-button size="min" color="#F0B80D" v-if="item.status==1" style="width: 50px;height: 28px" @click="cancelTask(item.id)">
											<span style="font-size: 14px" >{{ $t("hall.kbip[0]") }}</span></van-button>
									</td>
								</tr>
								<tr>
									<td>
										{{ $t("hall.list[1]") }} {{parseFloat(item.price).toFixed(2)}}
									</td>
									<td>
										{{ $t("hall.list[0]") }} {{parseFloat(item.total_number).toFixed(2)}} USDT
									</td>
								</tr>
								<tr>
									<td> {{ $t("hall.list[2]") }} {{parseFloat(item.remains_number).toFixed(2)}} USDT</td>
									<td> </td>
								</tr>

							</table>
<!--							<div style="width:100%;height:1px; background:#E0E0E0;"></div>-->
					</div>
				</van-list>
			</div>
		</div>

	</div>

</template>

<script>
	export default {
		name: 'Wallet',
		components: {

		},
		props: ['walletType','id'],
		data() {
			return {
				active:2,
				isLoad: false,//显示正在加载
				isFinished: false,//控制是否禁止触发上拉触底的事件
				pageNo:1,
				pagesize:20,
				listData:[],
				robotUserName:localStorage['robotUserName']||''
			}
		},
		created() {
			this.active = 2;
			this.listData = [];
			for (var i = 1; i < 3; i++) {
				this.listData[i] = [];
			}
			this.getListData("init");
		},
		mounted() {

		},
		methods: {
			tabChanged(index){
				this.active = index;
				this.getListData("init");
			},
			cancelTask(id) {
				this.$Model.CancelTask({
					order_id: id,
					status: 2
				}, (data) => {
					if (data.code == 1) {
						this.getListData("init");
					}
					let msg = data.code_dec;
					switch (data.code) {
						case 1:
							msg = this.$t('common3[0]');
							break
						case -2:
							msg = this.$t('taskOrder2[0]');
							break
					}
					this.$Dialog.Toast(msg);
					this.getListData("init");
				});
			},
			//触底触发，页面初始化触发
			onLoad() {
				// 异步更新数据
				this.getListData("load");
			},
			//获取用户历史记录
			getListData(type) {
				this.isLoad = true;
				this.isRefresh = false;
				if (type == "load") {
					this.pageNo += 1;
				} else {
					this.pageNo = 1;
					this.isFinished = false;
				}
        var type = this.active;
        if (type==0){
          type =2;
        }
				let json={
					task_type: type,
					page_no: this.pageNo,
					is_u:1
				}
				this.pages++
				this.$Model.GetHallTaskList(json,
						(data) => {
					this.isLoad = false;
					if (data.code == 1) {
						if (type == "load") {
							this.listData[this.active] = this.listData[this.active].concat(data.info);
						} else if (data.info){
							this.listData[this.active] = data.info;
						}
						if (this.pageNo == data.data_total_page) {
							this.isFinished = true;
						} else {
							this.isFinished = false;
						}
					} else {
						this.listData[this.active] = "";
						this.isFinished = true;
					}
				})
			},
			onClickRight(){
				this.$router.push("/user/withdrawalCenter");
			},
		},
	}
</script>

<style scoped>
	/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #0f25fe;
} */
	.PageBox {
		overflow: auto;
		padding-top: 40px;
	}

	.PageBox .bgc {
		width: 100%;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;

	}

  .van-tabs >>> .van-tab--active{
    color: red;
  }

	.PageBox .bg{
	}

	.PageBox .panel{
		width: 95%;
		margin-left: 10px;
		margin-top: 10px;
	}

	.topTitle {
		position: absolute;
		max-width: 70%;
		white-space: nowrap;
		font-size: 18px;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
	}

	.PageBox>>>.mytable {
    background-color: var(--panel_color);
    border-radius: 10px;
		width: 98%;
		margin: 5px auto;
		margin-top: 6px;
		font-size: 18px;
		margin-bottom: 10px;
	}

	.PageBox>>>table {
		width: 100%;
		/*border: none;*/
		/* border-spacing: 1px; */
		/*border-collapse: collapse;*/
	}

	.PageBox>>>th:nth-child(1) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 26%;
	}

	.PageBox>>>th {
		border-right: 1px solid #FFFFFF !important;
	}

	.PageBox>>>table tr td {
		font-size: 11px;
		color: #626262;
		padding: 8px 0px;
		text-align: center;
		border: none;
		/*border-right: 1px solid #FFFFFF !important;*/
	}

	table tbody tr:nth-child(odd) {
		background: #eff0f2;
	}

	table tbody tr:nth-child(even) {
		background: #e1e1f9;
	}

	/* .PageBox >>> .mytable {
	  background: #eff0f2;
	  width: 90%;
	  margin: 0 auto;
	  margin-top: 20px;
	}
	.PageBox >>> table {
	  width: 100%;
	  margin: 0 5% 0 5%;
	  padding-bottom: 20px;
	  border-spacing: 0;
	}
	.PageBox >>> th {
	  background: #f5f6ff;
	  font-weight: 700;
	  font-size: 18px;
	  position: relative;
	}
	.PageBox >>> th:nth-of-type(5) {
	  border: none;
	}
	.PageBox >>> .tabHead div {
	  height: 12px;
	  width: 1px;
	  background: #b9babd;
	  position: absolute;
	  left: 100%;
	  top: 10px;
	  z-index: 10;
	}
	.PageBox >>> table tr td {
	  height: 41px;
	  color: #626262;
	  font-size: 15.78px
	} */
</style>
