<template>
  <van-tabbar
      v-model="tabbarIndex"
      @click="activeTabbar()"
      safe-area-inset-bottom
      :border="false"
      z-index="99"
      :fixed="true"
  >
    <van-tabbar-item to="/" name="home" class="home">
      <img
          style="width: 26px; height: 26px"
          slot="icon"
          slot-scope="props"
          :src="props.active ? icon_nav1.active : icon_nav1.normal"
          size="30"
      />
      {{ $t("footer[0]") }}
    </van-tabbar-item>
    <van-tabbar-item name="hall" class="task" @click="clickHall">
      <img
          style="width: 26px; height: 26px"
          slot="icon"
          slot-scope="props"
          :src="props.active ? icon_nav2.active : icon_nav2.normal"
      />
      {{ $t("footer[1]") }}
    </van-tabbar-item>
    <van-tabbar-item to="/myTask" name="myTask" class="task">
      <img
          style="width: 26px; height: 26px"
          slot="icon"
          slot-scope="props"
          :src="props.active ? icon_nav3.active : icon_nav3.normal"
      />
      {{ $t("footer[2]") }}
    </van-tabbar-item>
    <van-tabbar-item to="/user" name="user" class="user">
      <img
          style="width: 26px; height: 26px"
          slot="icon"
          slot-scope="props"
          :src="props.active ? icon_nav4.active : icon_nav4.normal"
      />
      {{ $t("footer[3]") }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import icon1 from '@/assets/img/new/foot/icon1.png'
import icon1_a from '@/assets/img/new/foot/icon1_a.png'
import icon2 from '@/assets/img/new/foot/icon2.png'
import icon2_a from '@/assets/img/new/foot/icon2_a.png'
import icon3 from '@/assets/img/new/foot/icon3.png'
import icon3_a from '@/assets/img/new/foot/icon3_a.png'
import icon4 from '@/assets/img/new/foot/icon4.png'
import icon4_a from '@/assets/img/new/foot/icon4_a.png'
export default {
  name: "Footer",
  components: {},
  props: [],
  data() {
    return {
      tabbarIndex: this.$route.matched[0].meta.active,
      icon_nav1: {
        normal: icon1,
        active: icon1_a,
      },
      icon_nav2: {
        normal: icon2,
        active: icon2_a,
      },
      icon_nav3: {
        normal: icon3,
        active: icon3_a,
      },
      icon_nav4: {
        normal: icon4,
        active: icon4_a,
      },
      icon_nav5: {
        normal: "./skinlscc/user.png",
        active: "./skinlscc/user_select.png",
      },
      icon_nav6: {
        normal: "./skinlscc/profit.png",
        active: "./skinlscc/profit_select.png",
      },
      icon_nav7: {
        normal: "./skinlscc/activity.png",
        active: "./skinlscc/activity_select.png",
      },
    };
  },
  computed: {},
  watch: {
    tabbarIndex(val) {
      console.log(this.tabbarIndex);
    },
  },
  created() {},
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    activeTabbar(index) {
      console.log(this.tabbarIndex);
    },
    clickHall(){
      let userinfo = this.UserInfo;
      // this.$router.push("/hall");
      this.$Model.TradeStatus((data) => {
        if (data['code']==1){
          let info = data['info'];
          if (info['bindAccount']==1) {
            this.$Dialog.Toast(this.$t('common4[7]'));
            this.$router.push("/user/bindAccount");
          }else if (info['shimingauth']==1){
            // this.$Dialog.Toast(this.$t('common4[8]'));
            this.$router.push("/shimingauth");
          }else if (info['busauth']==1){
            this.$Dialog.Toast(this.$t('common4[8]'));
            this.$router.push("/busauth");
          }else if (info['islock']==2){
            this.$Dialog.Toast(this.$t('common5[5]'));
            this.$router.go(-1);
          }else {
            this.$router.push("/hall");
          }
        }
      });

    }
  },
};
</script>
<style scoped>
.van-tabbar {
  height: 62px;
  /* background: url('../../public/skin/home/bg_menu.png') no-repeat center 0; */
  background-size: contain;
  background-color: var(--tabbar_bg);
  /*border:2px solid #DCDCDC;*/
  /*box-shadow: 3px 3px 0px 0px #888;*/
  /*background: #0e1526;*/
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}

.van-tabbar-item__icon {
  font-size: 24px;
}

.van-tabbar-item__icon img {
  height: 24px;
}

.van-tabbar-item__icon .addTask {
  height: 72px;
  margin: -38px 0 -10px;
}

.van-tabbar-item--active {
  background:var(--tabbar_bg);
}

.van-tabbar-item--active >>> .van-tabbar-item__text {
  background: var(--tabbar_active);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.van-tabbar-item >>> .van-tabbar-item__text {
  font-size: 13px !important;
  color: var(--tabbar_inactive);
  font-family: Alibaba !important;
  margin-top: 2px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.van-tabbar >>> .van-tabbar-item {
  width: 20%;
}
</style>
